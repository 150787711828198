<template>
  <div class="ProductServe">
    <div class="top">
      <van-image :src="require('@/assets/img/ProductServe_01.jpg')" />
    </div>
    <div class="payroll_tax">
      <h3 class="title">薪税服务</h3>
      <p class="notes">新一代数字化薪税管理</p>
      <div class="payroll_tax_box">
        <!-- <div
          class="payroll_tax_box_lf"
          @click="jumpClick('薪税外包服务', '/productServe/details')"
        >
          <h3>薪税外包服务</h3>
          <p>一站式薪社税服务/定制化</p>
          <div>了解详情</div>
        </div> -->
        <div class="right">
          <div
            class="payroll_tax_box_rt"
            @click="
              jumpClick(
                '薪税外包服务',
                'https://wms.ciicpt.com.cn/#/m3?id=2567489e669346d99b'
              )
            "
          >
            <h4 class="van-ellipsis">薪税外包服务</h4>
            <p>专家带团队/双语服务</p>
            <div>了解详情</div>
          </div>
          <div
            class="payroll_tax_box_rt"
            @click="
              jumpClick(
                '薪税咨询服务',
                'https://wms.ciicpt.com.cn/#/m3?id=71c33f388049454e9f'
              )
            "
          >
            <h4 class="van-ellipsis">薪税咨询服务</h4>
            <p>线上平台税务对接</p>
            <div>了解详情</div>
          </div>

          <div
            class="payroll_tax_box_rt"
            @click="
              jumpClick(
                '股权期权服务',
                'https://wms.ciicpt.com.cn/#/m3?id=c9a500bdef224c9992'
              )
            "
          >
            <h4 class="van-ellipsis">股权期权服务</h4>
            <p>专家带团队/双语服务</p>
            <div>了解详情</div>
          </div>
          <div
            class="payroll_tax_box_rt"
            @click="
              jumpClick(
                '客户服务平台定制服务',
                'https://wms.ciicpt.com.cn/#/m3?id=4638914922d745a99d'
              )
            "
          >
            <h4 class="van-ellipsis">客户服务平台定制服务</h4>
            <p>线上平台税务对接</p>
            <div>了解详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="finance_tax">
      <h3 class="title">财税服务</h3>
      <p class="notes">合法合规高效节税</p>
      <div class="finance_tax_List">
        <div
          class="finance_tax_box"
          v-for="(item, index) in financeTaxList"
          :key="index"
          @click="jumpClick(item.title, item.url)"
        >
          <h5>{{ item.title }}</h5>
          <p>{{ item.text }}</p>
          <p>{{ item.text2 }}</p>
        </div>

        <!-- <van-image :src="require('@/assets/img/ProductServe_05.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_06.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_07.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_08.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_09.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_10.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_11.jpg')" />
        <van-image :src="require('@/assets/img/ProductServe_12.jpg')" /> -->
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">财税服务</h3>
      <p class="notes">合法合规高效节税</p>
      <div class="banner">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <!-- <img v-lazy="image" /> -->
            <van-image width="100%" lazy-load fit="cover" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="bottom">
      <h3 class="title">财税服务</h3>
      <p class="notes">合法合规高效节税</p>
      <div class="banner">
        <van-swipe :autoplay="3000">
          <van-swipe-item
            v-for="(image, index) in images2"
            :key="index"
            @click="onClick(image, index)"
          >
            <!-- <img v-lazy="image" /> -->
            <van-image width="100%" lazy-load fit="cover" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage, Swipe, SwipeItem, Lazyload } from "vant";
import { addTPv } from "@/api/index";
import copyrightIp from "@/components/copyrightIp";
const envUrl = process.env.VUE_APP_API_URL;
export default {
  components: {
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Lazyload.name]: Lazyload,
    copyrightIp,
  },
  data() {
    return {
      images: [
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
      ],
      images2: [
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
        require("@/assets/img/ProductServe_13.png"),
      ],
      financeTaxList: [
        {
          title: "财税全流程服务",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: `${envUrl}#/m3?id=11f92fde2d714d4b82`,
        },
        {
          title: "常年税务服务",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: "",
        },
        {
          title: "特殊税务服务",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: "",
        },
        {
          title: "对外支付服务",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: `${envUrl}#/m3?id=4e8b021687f44ed781`,
        },
        {
          title: "工会经费管理",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: `${envUrl}#/m3?id=4e8b021687f44ed781`,
        },
        {
          title: "财务整理和审阅",
          text: "央企背书全国布网",
          text2: "双语专业服务",
          url: `${envUrl}#/m3?id=1ad77509d6664ce0a6`,
        },
        // {
        //   title: "工会经费管理",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "/productServe/details",
        // },
        // {
        //   title: "对外支付服务",
        //   text: "央企背书全国布网",
        //   text2: "双语专业服务",
        //   url: "/productServe/details",
        // },
      ],
    };
  },
  methods: {
    onClick(data, index) {
      // this.$router.push("/productServe/details");

      window.location.href = `${envUrl}#/m3?id=c9a500bdef224c9992`;
      // "https://wms.ciicpt.com.cn/#/m3?id=c9a500bdef224c9992";
    },
    jumpClick(title, url) {
      if (url) {
        window.location.href = url;
      }

      // let pvMenuName = title;
      // let pvOpenid = localStorage.getItem("code") || "";
      // let pvIp = localStorage.getItem("Ip") || "";
      // addTPv({ pvMenuName, pvOpenid, pvIp }).then((res) => {});
      // this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.ProductServe {
  .top {
    .van-image {
      width: 100%;
    }
  }
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  .notes {
    padding: 7px 0;
    font-size: 16px;
    color: #808492;
  }
  .payroll_tax {
    width: 100%;
    padding: 10px;
    .payroll_tax_box {
      width: 100%;
      height: 196px;
      display: flex;

      .payroll_tax_box_lf {
        width: 166px;
        min-width: 166px;
        height: 196px;
        padding: 5px;
        border-radius: 8px;
        background-image: url("../../assets/img/ProductServe_02.png");
        background-size: 100%;
        // background-repeat: no-repeat;
        h3 {
          font-size: 20px;
          font-weight: bold;
        }
        p {
          font-size: 12px;
          color: #999999;
          padding-bottom: 10px;
        }
        div {
          width: 75px;
          line-height: 22px;
          background: #ffefad;
          text-align: center;
          font-size: 12px;
          border-radius: 3px;
        }
      }
      .van-image {
        border-radius: 6px;
        overflow: hidden;
      }
      .left {
        width: 182px;
        min-width: 182px;
        padding-right: 10px;
      }
      .right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
        grid-column-gap: 10px;

        .payroll_tax_box_rt {
          width: 100%;
          border-radius: 8px;
          background-image: url("../../assets/img/ProductServe_03.png");
          background-size: 100%;
          padding: 10px;
          background-repeat: no-repeat;
          overflow: hidden;

          &:nth-child(2) {
            background-image: url("../../assets/img/ProductServe_04.png");
            div {
              background: #a0ddff;
            }
          }
          h4 {
            font-size: 16px;
          }
          p {
            font-size: 12px;
            color: #999999;
            padding: 6px 0;
          }

          div {
            width: 75px;
            line-height: 22px;
            background: #fec19e;
            text-align: center;
            font-size: 12px;
            border-radius: 3px;
          }
        }
      }
    }
  }
  .finance_tax {
    width: 100%;
    padding: 10px;
    .finance_tax_List {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, 94px);
      grid-row-gap: 10px;
      grid-column-gap: 10px;
      .finance_tax_box {
        color: #fff;
        font-size: 12px;
        padding: 6px;
        padding-right: 50px;
        background-size: 100%;
        background-repeat: no-repeat;
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        h5 {
          font-size: 14px;
          width: 100%;
          padding-bottom: 5px;
          font-weight: bold;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-size: 100%;
          background-repeat: no-repeat;
          z-index: -1;
        }
        &:nth-child(1) {
          h5 {
            background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_05.png");
          }
        }
        &:nth-child(2) {
          h5 {
            // background: #ee9722;
            background: transparent;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_06.png");
          }
        }
        &:nth-child(3) {
          h5 {
            // background: #88a5b1;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_07.png");
          }
        }
        &:nth-child(4) {
          h5 {
            // background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_08.png");
          }
        }
        &:nth-child(5) {
          h5 {
            // background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_09.png");
          }
        }
        &:nth-child(6) {
          h5 {
            // background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_10.png");
          }
        }
        &:nth-child(7) {
          h5 {
            // background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_11.png");
          }
        }
        &:nth-child(8) {
          h5 {
            // background: #ff6337;
          }
          &::after {
            background-image: url("../../assets/img/ProductServe_12.png");
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    padding: 10px;
    .banner {
      .van-image {
        width: 100%;
        height: 180px;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
</style>
