var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ProductServe"},[_c('div',{staticClass:"top"},[_c('van-image',{attrs:{"src":require('@/assets/img/ProductServe_01.jpg')}})],1),_c('div',{staticClass:"payroll_tax"},[_c('h3',{staticClass:"title"},[_vm._v("薪税服务")]),_c('p',{staticClass:"notes"},[_vm._v("新一代数字化薪税管理")]),_c('div',{staticClass:"payroll_tax_box"},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"payroll_tax_box_rt",on:{"click":function($event){return _vm.jumpClick(
              '薪税外包服务',
              'https://wms.ciicpt.com.cn/#/m3?id=2567489e669346d99b'
            )}}},[_c('h4',{staticClass:"van-ellipsis"},[_vm._v("薪税外包服务")]),_c('p',[_vm._v("专家带团队/双语服务")]),_c('div',[_vm._v("了解详情")])]),_c('div',{staticClass:"payroll_tax_box_rt",on:{"click":function($event){return _vm.jumpClick(
              '薪税咨询服务',
              'https://wms.ciicpt.com.cn/#/m3?id=71c33f388049454e9f'
            )}}},[_c('h4',{staticClass:"van-ellipsis"},[_vm._v("薪税咨询服务")]),_c('p',[_vm._v("线上平台税务对接")]),_c('div',[_vm._v("了解详情")])]),_c('div',{staticClass:"payroll_tax_box_rt",on:{"click":function($event){return _vm.jumpClick(
              '股权期权服务',
              'https://wms.ciicpt.com.cn/#/m3?id=c9a500bdef224c9992'
            )}}},[_c('h4',{staticClass:"van-ellipsis"},[_vm._v("股权期权服务")]),_c('p',[_vm._v("专家带团队/双语服务")]),_c('div',[_vm._v("了解详情")])]),_c('div',{staticClass:"payroll_tax_box_rt",on:{"click":function($event){return _vm.jumpClick(
              '客户服务平台定制服务',
              'https://wms.ciicpt.com.cn/#/m3?id=4638914922d745a99d'
            )}}},[_c('h4',{staticClass:"van-ellipsis"},[_vm._v("客户服务平台定制服务")]),_c('p',[_vm._v("线上平台税务对接")]),_c('div',[_vm._v("了解详情")])])])])]),_c('div',{staticClass:"finance_tax"},[_c('h3',{staticClass:"title"},[_vm._v("财税服务")]),_c('p',{staticClass:"notes"},[_vm._v("合法合规高效节税")]),_c('div',{staticClass:"finance_tax_List"},_vm._l((_vm.financeTaxList),function(item,index){return _c('div',{key:index,staticClass:"finance_tax_box",on:{"click":function($event){return _vm.jumpClick(item.title, item.url)}}},[_c('h5',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))]),_c('p',[_vm._v(_vm._s(item.text2))])])}),0)]),_c('div',{staticClass:"bottom"},[_c('h3',{staticClass:"title"},[_vm._v("财税服务")]),_c('p',{staticClass:"notes"},[_vm._v("合法合规高效节税")]),_c('div',{staticClass:"banner"},[_c('van-swipe',{attrs:{"autoplay":3000}},_vm._l((_vm.images),function(image,index){return _c('van-swipe-item',{key:index},[_c('van-image',{attrs:{"width":"100%","lazy-load":"","fit":"cover","src":image}})],1)}),1)],1)]),_c('div',{staticClass:"bottom"},[_c('h3',{staticClass:"title"},[_vm._v("财税服务")]),_c('p',{staticClass:"notes"},[_vm._v("合法合规高效节税")]),_c('div',{staticClass:"banner"},[_c('van-swipe',{attrs:{"autoplay":3000}},_vm._l((_vm.images2),function(image,index){return _c('van-swipe-item',{key:index,on:{"click":function($event){return _vm.onClick(image, index)}}},[_c('van-image',{attrs:{"width":"100%","lazy-load":"","fit":"cover","src":image}})],1)}),1)],1)]),_c('copyrightIp')],1)}
var staticRenderFns = []

export { render, staticRenderFns }